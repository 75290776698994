<template>
  <div>
    <h3>Select filters</h3>

    <div class="pickers">
      <base-picker
        @input="changeMetric"
        :value="metric"
        :items="performance_metrics"
        :item-label="item => item.name"
        :item-value="item => item"
        :accessibility="'value'"
        :error="null"
        :hide-feedback="true"
        label="Performance metric"
        placeholder="Select performance metric"
        input-name="performance-metrics"
        input-id="performance-metrics"
        class="w-full"
      />
      <base-picker
        @input="modifier = $event"
        :value="modifier"
        :items="filterModifier"
        :item-label="item => item.name"
        :item-value="item => item"
        :disabled="!metric || (metric && hasFixedModifier)"
        :accessibility="'value'"
        :error="null"
        :hide-feedback="true"
        label="Modifier"
        placeholder="Modifier"
        input-id="modifier"
        input-name="modifier"
        class="w-full"
      />
      <base-picker
        v-if="metric && hasFixedModifier"
        :disabled="!modifier && !hasFixedModifier"
        @input="value = $event"
        :value="value"
        :items="filterValues"
        :item-label="item => item.value"
        :item-value="item => item"
        :accessibility="'value'"
        :error="null"
        :hide-feedback="true"
        label="Value input"
        placeholder="Select value"
        input-id="number-input"
        input-name="number-input"
        class="w-full"
      />
      <input-text
        v-else
        v-model="value"
        :disabled="!modifier"
        :input-type="metric && metric.type === 'number' ? 'number' : 'text'"
        :currencyInput="isCurrencyField ? currency : null"
        :hide-feedback="true"
        class="w-full"
        label="Value input"
        input-id="value"
        input-name="value"
      >
        <template
          #prefix
          v-if="metric && metric.prefix !== null"
        >
          <span v-if="metric.prefix === 'currency'">{{ currency }}</span>
          <span v-if="metric.prefix === 'percentage'">%</span>
        </template>
      </input-text>
    </div>
    <shape>
      <template #footer>
        <div class="flex gap-5 justify-center">
          <the-button
            secondary
            @click="$emit('close')"
          >
            <template #default> Cancel </template>
          </the-button>
          <the-button
            :disabled="!disableSaveButton"
            primary
            @click="saveFilter"
          >
            <template #default>
              {{ filter ? 'Update filter' : 'Save & add filter' }}
            </template>
          </the-button>
        </div>
      </template>
    </shape>
  </div>
</template>

<script>
import BasePicker from '@/components/input/base/BasePicker'
import InputText from '@/components/input/InputText'
import { mapGetters } from 'vuex'
import * as Sentry from '@sentry/vue'

export default {
  name: 'FiltersContainer',
  components: { InputText, BasePicker },
  props: {
    metrics: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      performance_metrics: this.metrics,
      modifiers: [
        { name: 'Greater than', value: 'greater_than', type: 'number' },
        { name: 'Less than', value: 'less_than', type: 'number' },
        { name: 'Equals to', value: 'equals_to', type: 'number' },
        { name: 'Contains', value: 'contains', type: 'text' },
        { name: 'Does not contain', value: 'does_not_contain', type: 'text' },
        { name: 'Exact match', value: 'exact_match', type: 'text' },
        { name: 'Boolean', value: 'boolean', type: 'boolean' },
        { name: 'Equals to', value: 'is_any_of', type: 'is_any_of' }, // Temporary name for Is any of
      ],
      default_values: [
        { value: true, type: 'boolean' },
        { value: false, type: 'boolean' },
      ],
      metric_values: [],
      metric: null,
      modifier: null,
      value: null,
    }
  },
  computed: {
    ...mapGetters({
      currencyById: 'currency/getCurrencyById',
      store: 'store/selectedStore',
    }),
    filterModifier() {
      if (this.metric) {
        return this.modifiers.filter(m => m.type === this.metric.type)
      }
      return this.modifiers
    },
    filterValues() {
      if (this.metric) {
        let metric_values = this.metric_values.filter(mv => mv.metric === this.metric.value)
        if (metric_values.length) {
          return metric_values
        }
        return this.default_values.filter(v => v.type === this.metric.type)
      }
      return this.default_values
    },
    disableSaveButton() {
      if (this.metric && this.hasFixedModifier) {
        return this.metric && this.value
      }
      return this.metric && this.modifier && this.value
    },
    currency() {
      if ([39, 42, 132].includes(this.store.currency_id)) {
        return this.currencyById(this.store.currency_id)?.symbol || 'N/A'
      } else {
        return this.currencyById(this.store.currency_id)?.code || 'N/A'
      }
    },
    isCurrencyField() {
      if (this.metric) {
        return ['cpc', 'cost', 'profit', 'conv_value'].includes(this.metric.value)
      }
      return false
    },
    hasFixedModifier() {
      if (this.metric) {
        return ['boolean', 'is_any_of'].includes(this.metric.type)
      }
      return false
    },
  },
  watch: {
    metric() {
      this.value = null
    },
  },
  methods: {
    changeMetric(event) {
      this.metric = event
      this.modifier = this.modifiers.find(mod => mod.type === event.type)
      if (undefined === this.modifier) {
        this.modifier = null
        this.value = null
      }
    },
    saveFilter() {
      let btnText
      if (this.hasFixedModifier) {
        btnText = `${this.metric.name} ${typeof this.value === 'string' ? this.value : this.value.value}`
      } else {
        btnText = `${this.metric.name} ${this.modifier.name.toLowerCase()} ${typeof this.value === 'string' ? this.value : this.value.value}`
      }

      let prefix = null
      if (this.metric.prefix) prefix = this.metric.prefix === 'currency' ? this.currency : '%'

      const filter = {
        id: this.filter ? this.filter.id : null,
        metric: this.metric,
        modifier: this.modifier,
        value: this.value,
        text: btnText,
        prefix,
      }

      if (!this.filter) {
        delete filter.id
      }
      this.$emit(this.filter ? 'editFilter' : 'savedFilter', filter)
    },
    async getFilterValues(metric) {
      try {
        const response = await axios.get(`product/get-filter-options/${metric}`)
        if (response.data.data.length) {
          response.data.data.forEach(val => this.metric_values.push({ value: val, metric: metric }))
        }
      } catch (e) {
        Sentry.captureException(e)
        this.errorMessage = `Could not get ${metric} filter options`
      }
    },
  },
  async mounted() {
    if (this.performance_metrics.some(metric => metric.value === 'availability')) {
      await this.getFilterValues('availability')
    }
    if (this.filter) {
      const { value, metric, modifier } = this.filter
      this.value = value
      this.metric = metric
      this.modifier = modifier
    }
  },
}
</script>

<style scoped lang="scss">
.pickers {
  @apply my-4 flex flex-col gap-5;
}

@screen lg {
  .pickers {
    @apply my-4 flex flex-row gap-5;
  }
}
</style>
