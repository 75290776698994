<template>
  <div>
    <shape slot-full-width>
      <template #default>
        <table id="table_id">
          <thead>
            <tr>
              <th
                scope="col"
                @click.stop="
                  !header.checkbox && header.hasSort ? $emit('sort', { header, index: headerIndex(i) }) : null
                "
                v-for="(header, i) in adjHeader"
                :key="'thead-' + i"
              >
                <base-tri-state-checkbox
                  style="margin-left: 2px"
                  v-if="header.checkbox"
                  :value="chState"
                  v-model="chState"
                  @triStateCheck="masterCheckbox"
                  :input-id="'masterCheckbox'"
                  :input-name="'masterCheckbox'"
                >
                </base-tri-state-checkbox>
                <div
                  v-else
                  class="flex gap-2 items-center"
                >
                  <bb-icon
                    v-if="header.hasSort"
                    :class="header.sort === 'asc' ? 'isSort' : null"
                    icon="ic-bold-arrow"
                    :size="12"
                  />
                  <slot
                    v-if="header.headerSlot"
                    :name="'header-' + header.slot_name"
                    :header="header"
                  />
                  <span v-else>{{ header.header }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th
                v-if="loading"
                scope="col"
                colspan="100%"
                class="indeterminate-progress-bar"
              >
                <div class="indeterminate-progress-bar__progress"></div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="[key, item] in mappedToUnique">
              <td
                v-for="(header, i) in adjHeader"
                :style="columnDefs[i]"
              >
                <base-tri-state-checkbox
                  v-if="header.checkbox"
                  :disabled="masterCheckboxValue"
                  :value="chState ? chState : isChecked(key)"
                  :input-id="key.toString()"
                  :input-name="key.toString()"
                  @input="selectItem(key)"
                ></base-tri-state-checkbox>
                <slot
                  v-else
                  :name="header.slot_name"
                  :value="accessibility(item, header)"
                  :item="item"
                  :selectProduct="selectItem"
                  :isChecked="isChecked(key)"
                  :i="key"
                />
              </td>
            </tr>
          </tbody>
          <tfoot v-if="checkIfFooterExists">
            <tr>
              <th
                scope="col"
                :class="i !== 0 ? 'existing_column' : null"
                v-if="header.footerAccessibility"
                v-for="(header, i) in adjHeader"
              >
                <slot
                  :items="footerItems"
                  :header="header"
                  :name="`tfoot-${header.slot_name}`"
                />
              </th>
              <th
                scope="col"
                v-else
              ></th>
            </tr>
          </tfoot>
        </table>
      </template>
      <template #footer>
        <slot name="pagination" />
      </template>
    </shape>
  </div>
</template>

<script>
import IcChevronDown from 'vue-material-design-icons/ChevronDown'
import IcChevronUp from 'vue-material-design-icons/ChevronUp'
import Loader from '@/components/loader/Loader'
import BaseCheckBox from '@/components/input/base/BaseCheckBox'
import BaseTriStateCheckbox from '@/components/input/base/BaseTriStateCheckbox'

export default {
  name: 'DataTable',
  components: { BaseTriStateCheckbox, BaseCheckBox, Loader, IcChevronDown, IcChevronUp },
  data() {
    return {
      chState: false,
      masterCheckboxValue: false,
      selectedItems: new Map(),
      tableInstance: null,
      mappedItems: new Map(),
    }
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    footerItems: {
      type: Array | Object,
    },
    hasCheckBox: {
      type: Boolean,
      default: false,
    },
    columnDefs: {
      type: Array,
    },
    tableDisplayStyle: {
      type: Number,
      default: 2,
    },
  },
  watch: {
    items() {
      this.mappedItems.clear()
      setTimeout(() => {
        this.tableInstance.columns.adjust()
      }, 30)
    },
    selectedItems(items) {
      if (items.length) {
        this.chState = null
      } else {
        this.chState = false
      }
    },
  },
  computed: {
    mappedToUnique() {
      const page = this.$route.query.page ? this.$route.query.page : 1
      const limit = this.$route.query.limit ? this.$route.query.limit : 50

      this.items.forEach((item, i) => {
        const index = (page - 1) * limit + (i + 1)
        this.mappedItems.set(index, item)
      })
      return this.mappedItems
    },
    adjHeader() {
      if (this.hasCheckBox) {
        return [{ checkbox: true }, ...this.headers]
      } else {
        return this.headers
      }
    },
    checkIfFooterExists() {
      return this.headers.some(header => header.footerAccessibility)
    },
  },
  methods: {
    masterCheckbox(value) {
      this.masterCheckboxValue = (value.id === 'masterCheckbox' && value.state === null) || value.state === true

      if (value.state === null || value.state === true) {
        this.$emit('selectAll', value)
      } else {
        this.selectedItems.clear()
        this.$emit('selected', [])
        this.$emit('selectAll', value)
      }
    },
    selectItem(key) {
      const itemFound = this.selectedItems.has(key)

      if (itemFound) {
        this.selectedItems.delete(key)
      } else {
        this.selectedItems.set(key, this.mappedItems.get(key))
      }

      this.$emit(
        'selected',
        [...this.selectedItems].map(([_, value]) => value),
      )
    },
    isChecked(key) {
      return this.selectedItems.has(key)
    },
    accessibility(item, header) {
      const splitAccessibility = header.accessibility.split('.')
      let object = { ...item }
      if (splitAccessibility.length > 1) {
        splitAccessibility.forEach(accessibility => {
          object = object[accessibility]
        })
        return object
      }
      return item[header.accessibility]
    },
    headerIndex(index) {
      return this.hasCheckBox ? index - 1 : index
    },
    displayTable() {
      let options = {
        searching: false,
        bLengthChange: false,
        bInfo: false,
        paging: false,
        ordering: false,
        scrollX: true,
        scrollCollapse: true,
        destroy: true,
      }

      this.tableInstance = new DataTable('#table_id', options)

      this.adjHeader.forEach((header, i) => {
        const visibilePropertyExists = header.hasOwnProperty('visible')
        if (visibilePropertyExists) {
          this.tableInstance.column(i).visible(header.visible)
        }
      })

      const tableWrapper = document.getElementById('table_id_wrapper')
      tableWrapper.getElementsByClassName('dataTables_scroll')[0].style.border = '1px solid #E9EBED'
      tableWrapper.getElementsByClassName('dataTables_scrollBody')[0].style['overflow-y'] = 'hidden'

      const tableEmpty = tableWrapper.getElementsByClassName('dataTables_empty')
      if (tableEmpty.length) {
        tableEmpty[0].style.display = 'none'
      }

      const tableScrollHead = tableWrapper.getElementsByClassName('dataTables_scrollHead')[0]
      const topBarOuterHeight = document.getElementsByClassName('navbar')[0].scrollHeight

      Object.assign(tableScrollHead.style, {
        position: 'sticky',
        top: `${topBarOuterHeight}px`,
        'z-index': 9,
        overflow: 'hidden',
        border: 0,
        width: '100%',
      })

      setTimeout(() => {
        this.tableInstance.columns.adjust()
      }, 30)
    },
  },
  mounted() {
    this.displayTable()
  },
}
</script>

<style scoped lang="scss">
@keyframes indeterminate-progress-bar {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}

table.dataTable {
  * {
    font-size: 13px;
  }

  width: 100% !important;

  td {
    min-width: 100px;
  }

  th {
    white-space: nowrap;
    word-break: break-all;
  }

  border-collapse: separate;

  @apply border-b-0 border-t-0 m-0;

  .dtfc-right-top-blocker {
    @apply bg-primary;
  }

  .dataTables_scrollHeadInner {
    @apply w-full;
  }

  thead {
    position: sticky;
    inset-block-start: 0;

    tr {
      @apply bg-bb-pale-grey;
      z-index: 1;

      th.indeterminate-progress-bar {
        padding: 0;
        background-color: #d1d5db;

        border-radius: 9999px;

        height: 0.1rem;

        position: relative;
        overflow: hidden;
        width: 100%;

        .indeterminate-progress-bar__progress {
          @apply bg-primary;

          border-radius: 9999px;

          position: absolute;
          bottom: 0;
          top: 0;
          width: 50%;

          animation-duration: 2s;
          animation-iteration-count: infinite;
          animation-name: indeterminate-progress-bar;
        }
      }
    }

    th,
    th.dtfc-fixed-left {
      @apply bg-bb-pale-grey border-0 py-5 px-2 cursor-pointer;
      font-size: 13px;

      .isSort {
        -webkit-transform: rotateX(180deg);
        transform: rotateX(180deg);
      }

      span {
        @apply align-middle inline-block;
      }
    }
  }

  tbody {
    position: relative;
    border-right: 1px solid;
    border-left: 1px solid;
    border-bottom: 1px solid;
    @apply border-bb-pale-grey;

    tr {
      &:nth-child(odd) {
        > td {
          @apply bg-white;
        }
      }

      &:nth-child(even) {
        > td {
          @apply bg-bb-light-grey;
        }
      }
    }

    td,
    td.dtfc-fixed-left {
      border: none;
    }

    td {
      min-height: 80px;
      height: 80px;
      max-height: 80px;

      & > div {
        width: max-content;
      }

      &.dtfc-fixed-left:nth-child(2) {
        border-right: 1px solid;
        @apply border-bb-pale-grey;
      }
    }
  }

  tfoot {
    th,
    th.dtfc-fixed-left {
      border: none;
      border-top: 1px solid;
      @apply bg-white py-4 px-2 text-left border-t-0 border-bb-pale-grey z-0 bg-bb-pale-grey;
    }

    th.dtfc-fixed-left {
      &:nth-child(2) {
        border-right: 1px solid;
        @apply border-bb-pale-grey;
      }
    }
  }
}
</style>
