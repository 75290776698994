<template>
  <base-alert type="regular">
    <template #title> Upgrade&nbsp;plan </template>
    <template #content>
      <slot name="content"></slot>
    </template>
    <template #action>
      <the-button
        @click="showSubscriptionModal"
        secondary
      >
        Upgrade&nbsp;now
        <template #icon>
          <ic-chevron-right :size="16" />
        </template>
      </the-button>
    </template>
  </base-alert>
</template>

<script>
import SubscriptionSelectionModal from '@/components/modals/SubscriptionSelectionModal'
import { mapGetters } from 'vuex'
import BaseAlert from '@/components/alert/BaseAlert'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'

export default {
  name: 'upgrade-subscription',
  components: { BaseAlert, IcChevronRight },
  computed: {
    ...mapGetters({
      storeId: 'store/selectedStoreId',
    }),
  },
  methods: {
    showSubscriptionModal() {
      this.$modal.show(SubscriptionSelectionModal, { storeId: this.storeId }, SubscriptionSelectionModal.modalProps)
    },
  },
}
</script>
