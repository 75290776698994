<template>
  <base-input-text
    @click="$emit('click')"
    :value="value"
    @input="$emit('input', $event)"
    @focus="$emit($event.type, $event)"
    @focusout="$emit($event.type, $event)"
    :input-type="inputType"
    :input-id="inputId"
    :input-name="inputName"
    :placeholder="placeholder"
    :required="required"
    :label="label"
    :loading="loading"
    :disabled="disabled"
    :success="success"
    :error="error || !!errorMessage"
    :error-message="errorMessage"
    :hide-feedback="hideFeedback"
    :loading-text="loadingText"
  >
    <template #prefix>
      <slot name="prefix" />
    </template>
    <template #info>
      <slot name="info" />
    </template>
    <template #suffix>
      <div
        :class="suffixClasses"
        v-if="!hideSuffix"
        @click="handleSuffixClicked"
      >
        <ic-check
          v-if="success"
          title="Done"
          :size="18"
        />
        <ic-close
          v-if="!success && !loading && !!value"
          title="Clear"
          :size="18"
        />
      </div>
    </template>
  </base-input-text>
</template>

<script>
import BaseInputText from '@/components/input/base/BaseInputText'
import IcClose from 'vue-material-design-icons/Close'
import IcCheck from 'vue-material-design-icons/Check'

export default {
  name: 'input-text',
  components: { IcCheck, IcClose, BaseInputText },
  props: {
    value: {
      type: [String, Array],
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    inputId: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideFeedback: {
      type: Boolean,
      default: false,
    },
    hideSuffix: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: 'Loading',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    suffixClasses() {
      return {
        'cursor-pointer': !this.disabled && !this.loading && !this.success,
        'text-success': this.success,
        'text-error': this.error || !!this.errorMessage,
      }
    },
  },
  methods: {
    handleSuffixClicked() {
      if (!this.disabled && !this.success && !this.loading && !!this.value) {
        this.$emit('input', '')
        document.getElementById(this.inputId).focus()
      }
    },
  },
}
</script>
