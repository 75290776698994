<template>
  <div class="tri-state">
    <input
      :id="inputId"
      ref="tri-state"
      v-indeterminate="value"
      type="checkbox"
      :name="inputName"
      :checked="value"
      :disabled="disabled"
      @click.stop="onInput"
    />
    <label :for="inputId"></label>
  </div>
</template>

<script>
export default {
  name: 'BaseTriStateCheckbox',
  props: {
    inputId: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onInput(event) {
      const state = this.value === false ? null : this.value !== true
      this.$emit('triStateCheck', { state, id: this.inputId })
      this.$emit('input', event.target.checked)
    },
  },
}
</script>

<style scoped lang="scss">
.tri-state {
  position: relative;

  input {
    &[type='checkbox'] {
      appearance: none;
      width: 24px;
      height: 24px;

      &:hover:before {
        @apply border-bb-neon-purple;
      }

      &:hover:after {
        @apply bg-bb-neon-purple;
      }

      &:disabled:hover:after {
        @apply bg-bb-cool-grey cursor-default;
      }

      &:disabled:hover:before {
        @apply border-bb-cool-grey cursor-default;
      }

      &:before {
        @apply border-2 border-bb-cool-grey block cursor-pointer;
        transition: border-color 120ms ease-in;
        content: '';
        width: 24px;
        height: 24px;
        border-radius: 2px;
        margin-top: auto;
        margin-bottom: auto;
      }

      &:checked + label::before {
        content: '';
        display: block;
        position: absolute;
        text-align: center;
        height: 24px;
        width: 24px;
        left: 0;
        top: 0;
        background-color: $primary;
        border-radius: 2px;
        border: 1px solid;
      }

      &:checked + label::after {
        content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
        display: block;
        position: absolute;
        top: 5px;
        left: 5px;
      }

      &:indeterminate:before {
        content: '';
        display: block;
        color: white;
        width: 24px;
        height: 24px;
        background-color: gray;
      }

      &:indeterminate:after {
        content: '';
        background: none;
        display: block;
        width: 10px;
        height: 10px;
        border: solid white;
        border-width: 2px 0 0 0;
        position: absolute;
        top: 11px;
        left: 7px;
      }
    }
  }
}
</style>
