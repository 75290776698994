<template>
  <a
    v-if="!routerLink"
    :class="classes"
    :href="hrefLink"
    :target="target"
    :rel="target === '_blank' ? 'noopener' : rel"
  >
    <slot name="text" />
    <slot name="icon" />
  </a>
  <router-link
    v-else
    :to="{ name: hrefLink }"
  >
    <slot name="text" />
    <slot name="icon" />
  </router-link>
</template>

<script>
export default {
  name: 'BidLink',
  props: {
    routerLink: {
      type: Boolean,
      required: true,
      default: true,
    },
    hrefLink: {
      type: String,
      required: true,
    },
    rel: {
      type: String,
    },
    target: {
      type: String,
      default: '_self',
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    inherit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      switch (true) {
        case this.primary:
          return 'primary'
        case this.secondary:
          return 'secondary'
        case this.inherit:
          return 'inherit'
      }
    },
  },
}
</script>

<style scoped lang="scss">
a {
  @apply inline-flex self-center;

  &.secondary {
    @apply text-bb-mid-grey;
  }

  &.inherit {
    color: inherit;
  }

  span {
    @apply ml-4;
  }
}
</style>
