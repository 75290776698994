<template>
  <div
    class="base-input-text__container relative"
    v-click-outside="closeDropdownList"
  >
    <label
      v-if="label"
      :for="inputId"
    >
      {{ label }}
    </label>
    <div
      class="base-input-text__input-wrapper"
      :class="inputWrapperClasses"
    >
      <div
        @click="toggleDropdownList"
        class="base-input-text__input mx-4 truncate"
        :class="{ 'cursor-pointer': !disabled && !loading, disabled: disabled || loading }"
      >
        {{ selectedText }}
      </div>
      <div
        @click="toggleDropdownList"
        class="base-input-text__affix-wrapper pr-4"
      >
        <div v-if="customIcon">
          <slot name="customIcon" />
        </div>
        <ic-chevron
          v-else
          :size="22"
        />
      </div>
    </div>
    <div
      v-show="showDropdown"
      class="dropdown-list border"
      :class="{ 'no-label': !label, 'rounded-none': this.removeRadius }"
      ref="dropdown-list"
      :id="`${inputId}-dropdown-list`"
    >
      <div
        v-if="!noItems && unselect"
        class="dropdown-item truncate"
        @click="selectItem(null)"
      >
        {{ unselect }}
      </div>
      <div
        v-for="item in items"
        :key="JSON.stringify(item)"
        @click="selectItem(item)"
        :data-item="JSON.stringify(item)"
        class="dropdown-item truncate"
      >
        {{ itemLabel(item) }}
      </div>

      <div
        v-if="noItems"
        class="dropdown-item text-bb-mid-grey italic"
      >
        {{ emptyFeedback }}
      </div>
    </div>
    <feedback-message
      v-if="!hideFeedback"
      :message="errorMessage"
    />
  </div>
</template>

<script>
import BaseInputText from '@/components/input/base/BaseInputText'
import IcChevron from 'vue-material-design-icons/ChevronDown'
import FeedbackMessage from '@/components/base/FeedbackMessage'

const ARROW_DOWN = 40,
  ARROW_UP = 38,
  TAB = 9,
  ENTER = 13,
  ESC = 27

/**
 * Note about focus list item:
 * Using index for focusing directly in html doesn't work because the values doesn't get reset. That's why we set focus
 * directly on the element.
 */
export default {
  name: 'base-picker',
  components: { FeedbackMessage, IcChevron, BaseInputText },
  props: {
    value: {
      default: null,
    },
    inputId: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    itemLabel: {
      type: Function,
      required: true,
    },
    itemValue: {
      type: Function,
      required: false,
    },
    accessibility: {
      type: String,
    },
    label: {
      type: String,
      default: null,
    },
    emptyFeedback: {
      type: String,
      default: 'Empty',
    },
    unselect: {
      type: String,
    },
    hideFeedback: {
      type: Boolean,
      default: false,
    },
    removeRadius: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      hasFocus: false,
      showDropdown: false,
      focusIndex: null,
    }
  },
  watch: {
    disabled(val) {
      if (!val) {
        this.showDropdown = false
      }
    },
    loading(val) {
      if (val) {
        this.showDropdown = false
      }
    },
    displayDropdownList(val) {
      if (!val) {
        this.focusIndex = null
      }
    },
    focusIndex(val, oldVal) {
      // remove focus from previous item, add focus to current item

      const list = document.getElementById(`${this.inputId}-dropdown-list`)

      if (oldVal !== null) {
        list.children[oldVal].classList.remove('focus')
      }

      if (val !== null) {
        list.children[val].classList.add('focus')
      }
    },
  },
  computed: {
    selectedText() {
      if (!this.value) {
        return this.placeholder
      }

      // add accessibility prop to access the object
      if (this.accessibility && this.items.length) {
        return this.itemLabel(
          this.items.filter(item => this.itemValue(item[this.accessibility]) === this.value[this.accessibility])[0],
        )
      }

      if (!!this.itemValue && !!this.itemLabel && this.items.length) {
        return this.itemLabel(this.items.filter(item => this.itemValue(item) === this.value)[0])
      }

      if (!!this.itemLabel) {
        return this.itemLabel(this.value)
      }

      return this.value
    },
    visibleItemsCount() {
      return this.items ? this.items.length : 0
    },
    noItems() {
      return this.visibleItemsCount === 0
    },
    inputWrapperClasses() {
      if (this.disabled) {
        return { disabled: true }
      }
      return {
        focus: this.hasFocus,
        success: this.success,
        error: this.error,
        'rounded-none': this.removeRadius,
      }
    },
    customIcon() {
      return !!this.$slots['customIcon']
    },
  },
  methods: {
    selectItem(item) {
      this.showDropdown = false
      if (!item) {
        return this.$emit('input', null)
      } else {
        this.$emit('input', !!this.itemValue ? this.itemValue(item) : item)
      }
    },
    toggleDropdownList() {
      if (!this.disabled && !this.loading) {
        this.showDropdown = !this.showDropdown
      }
    },
    closeDropdownList() {
      this.showDropdown = false
    },
    getItemByFocusIndex() {
      if (this.focusIndex === null) {
        return null
      }

      const list = document.getElementById(`${this.inputId}-dropdown-list`)
      const listItem = list.children[this.focusIndex]

      return listItem ? JSON.parse(listItem.dataset.item) : null
    },
    handleInputKeys(e) {
      switch (e.keyCode) {
        case TAB:
        case ESC:
          this.showDropdown = false
          break

        case ARROW_DOWN:
          this.handleDownPressed(e)
          break

        case ARROW_UP:
          this.handleUpPressed(e)
          break

        case ENTER:
          e.preventDefault()
          this.selectItem(this.getItemByFocusIndex())
          break
      }
    },
    handleDownPressed(e) {
      e.preventDefault()
      if (!this.showDropdown) {
        // If someone starts using arrows without any input
        this.showDropdown = true
      }
      // Next tick because showAll must be set before continuing
      this.$nextTick(() => {
        if (this.noItems) return

        if (this.focusIndex === null || this.focusIndex === this.visibleItemsCount - 1) {
          // If no focus exist or last item has focs then start with first item
          this.focusIndex = 0
        } else {
          // Give next item focus
          this.focusIndex++
        }
      })
    },
    handleUpPressed(e) {
      e.preventDefault()

      if (!this.showDropdown) {
        // If someone starts using arrows without any input
        this.showDropdown = true
      }
      // Next tick because showAll must be set before continuing
      this.$nextTick(() => {
        if (this.noItems) return

        if (this.focusIndex === null || this.focusIndex === 0) {
          // If no focus exist or first item has focs then start with last item
          this.focusIndex = this.visibleItemsCount - 1
        } else {
          // Give previous item focus
          this.focusIndex--
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
$item-height: 37px;
$list-height: calc(5 * 38px);
$input-height: 64px;

.dropdown-list {
  @apply absolute w-full bg-white rounded border-bb-cool-grey shadow-md;
  top: $input-height;
  border-radius: 8px;
  max-height: 192px;
  overflow-y: auto;
  z-index: 10;

  &.rounded-none {
    border-radius: 0;
  }

  &.no-label {
    top: $item-height;
  }
}

.dropdown-input {
  width: 100%;
  padding: 10px 16px;
  background: #edf2f7;
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
  min-height: $list-height;
  height: $list-height;
  max-height: $list-height;

  &:focus {
    background: #fff;
    border-color: #e2e8f0;
  }
}

.dropdown-item {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;
  min-height: $item-height;
  height: $item-height;
  max-height: $item-height;

  &:hover {
    @apply bg-bb-cool-grey;
  }

  &.focus {
    @apply bg-bb-pale-grey;
  }
}
</style>
