<template>
  <label
    :for="inputId"
    class="mt-0 relative default"
    :class="{ disabled: disabled }"
  >
    <input
      type="checkbox"
      :id="inputId"
      :class="{ circle }"
      :name="inputName"
      :checked="value"
      @change="$emit('input', $event.target.checked)"
      :disabled="disabled"
    />
    <slot
      v-if="$slots['label']"
      name="label"
    />
    <span
      v-else-if="label !== 'Checkbox'"
      class="ml-1"
    >
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'base-check-box',
  emits: ['input'],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputId: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Checkbox',
    },
    circle: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  @apply flex items-center;
}

label {
  @apply flex items-center mb-0;
  @apply cursor-pointer text-bb-mid-grey;

  &.disabled:hover {
    @apply cursor-default;
  }
}

input[type='checkbox'] {
  appearance: none;
  display: flex;
  align-items: center;
  $box: 24px;
  $check: 12px;
  $transition: 120ms;
  padding-left: $box + 8px;

  &.circle {
    @apply mt-0;
    &:before {
      border-radius: 50% !important;
      @apply border-2 border-bb-cool-grey;
    }

    &:after {
      border-radius: 50% !important;
      @apply bg-bb-neon-purple;
    }
  }

  &:before {
    @apply absolute cursor-pointer;
    @apply border-2 border-bb-cool-grey;
    transition: border-color $transition ease-in;
    content: '';
    width: $box;
    height: $box;
    left: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }

  &:after {
    @apply absolute cursor-pointer;
    @apply bg-bb-cool-grey border-bb-neon-purple;
    transition:
      background-color $transition ease-in,
      opacity $transition ease-in;
    opacity: 0;
    content: '';
    left: 6px;
    width: $check;
    height: $check;
    margin-top: auto;
    margin-bottom: auto;
  }

  &:checked:after {
    opacity: 1;
    @apply bg-bb-neon-purple;
  }

  &:checked:before {
    opacity: 1;
    @apply border-bb-neon-purple;
  }

  &:checked:hover:before {
    opacity: 1;
    @apply border-bb-neon-purple;
  }

  &:not(&:checked):after {
    opacity: 0;
  }

  &:hover:after {
    @apply bg-bb-neon-purple;
  }

  &:enabled:hover:after {
    opacity: 1;
    @apply bg-bb-neon-purple border-bb-neon-purple;
  }

  &:hover:before {
    @apply border-bb-mid-grey;
  }

  &:disabled:hover:after {
    @apply bg-bb-cool-grey cursor-default;
  }

  &:disabled:hover:before {
    @apply border-bb-cool-grey cursor-default;
  }
}
</style>
