<template>
  <div class="buttons-wrapper">
    <the-button
      ref="add-filter"
      outer-class="w-full"
      secondary
      @click.stop="!selectedRef ? showFilterContainer('add-filter', $event) : null"
    >
      <template #default> Add Filter </template>
      <template #icon>
        <ic-plus />
      </template>
      <template #transition-container>
        <div
          class="filters-container hover:flex"
          v-if="selectedRef === 'add-filter'"
        >
          <div
            class="bg-white p-4 border border- shadow-lg"
            v-click-outside="closeFilterContainer"
          >
            <filters-container
              :metrics="metrics"
              @savedFilter="savedFilter($event)"
              @close="closeFilterContainer"
            ></filters-container>
          </div>
        </div>
      </template>
    </the-button>
    <the-button
      v-if="filters.length"
      :disabled="!checkIfFilterHasAddFilterRef(filter.id)"
      v-for="(filter, i) in slicedFiltersArray"
      :ref="`btn-${i}`"
      :key="`btn-${i}`"
      @click.stop="showFilterContainer(`btn-${i}`)"
      chip
      inner-class="font-thin"
      :outer-class="`w-full ${selectedRef === `btn-${i}` ? 'bg-bb-pale-grey' : null}`"
    >
      <template #default>
        {{ filter.text }}
      </template>
      <template #icon>
        <ic-close
          @click.stop="filter.id !== 0 ? removeFilter(filter) : null"
          :size="18"
        />
      </template>
      <template #transition-container>
        <div
          :ref="`container-${i}`"
          :style="isInViewPort(`btn-${i}`, `container-${i}`)"
          class="filters-container"
        >
          <div
            class="bg-white p-4 border border- shadow-lg"
            v-if="selectedRef === `btn-${i}`"
            v-click-outside="closeFilterContainer"
          >
            <filters-container
              :metrics="metrics"
              :filter="filter"
              @editFilter="editFilter($event)"
              @close="selectedRef = null"
            ></filters-container>
          </div>
        </div>
      </template>
    </the-button>

    <the-button
      @click="showMore = true"
      transparent
      v-if="showMoreButton"
    >
      <template> +{{ pureFiltersArray.length - slicedFiltersArray.length }} filters </template>
    </the-button>
    <the-button
      transparent
      v-if="pureFiltersArray.length"
      @click="clearAll"
    >
      <template> Reset filters </template>
    </the-button>
  </div>
</template>

<script>
import FiltersContainer from '@/components/product/overview/ProductFiltersContainer.vue'
import IcClose from 'vue-material-design-icons/Close'
export default {
  name: 'Filters',
  components: { FiltersContainer, IcClose },
  props: {
    filters: Array,
    metrics: Array,
  },
  data() {
    return {
      selectedRef: null,
      showMore: false,
    }
  },
  computed: {
    slicedFiltersArray() {
      return !this.showMore ? this.filters.slice(0, 3) : this.filters
    },
    pureFiltersArray() {
      return this.filters.filter(filter => filter.id !== 0)
    },
    showMoreButton() {
      return this.pureFiltersArray.length - this.slicedFiltersArray.length > 0 && !this.showMore
    },
  },
  methods: {
    async showFilterContainer(ref) {
      if (ref === 'add-filter') {
        this.$emit('addFilter', { id: 0, text: 'New filter' })
      }
      this.selectedRef = ref
    },
    isInViewPort(btnRef, containerRef) {
      if (btnRef === this.selectedRef) {
        const rect = this.$refs[containerRef][0].getBoundingClientRect()
        const isVisible =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        return isVisible ? { left: `${rect.left}px` } : { right: '10px' }
      }
    },
    closeFilterContainer() {
      this.selectedRef = null
      const exists = this.filters.filter(filter => filter.id === 0).length
      if (exists) this.$emit('deleteFilter', { id: 0, text: 'New filter' })
    },
    async removeFilter(filter) {
      this.$emit('deleteFilter', filter)
    },
    async savedFilter(filter) {
      this.$emit('addFilter', filter)
      this.selectedRef = null
    },
    async editFilter(filter) {
      this.$emit('editFilter', filter)
      this.selectedRef = null
    },
    async clearAll() {
      this.$emit('clearAll')
      this.selectedRef = null
    },
    checkIfFilterHasAddFilterRef(ref) {
      return typeof ref === 'number' && ref !== 0
    },
  },
}
</script>

<style scoped lang="scss">
.buttons-wrapper {
  @apply grid grid-cols-1 gap-3;
}

@screen md {
  .buttons-wrapper {
    @apply flex flex-wrap gap-3;
  }

  .filters-container {
    @apply absolute mt-2 z-10;
    width: fit-content;
  }
}
@screen lg {
  .filters-container {
    @apply absolute mt-2 z-10;
    width: 750px;
  }
}
</style>
