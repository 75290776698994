<template>
  <base-picker
    input-id="page-size"
    input-name="page-size"
    :items="items"
    :value="selectBoxValue"
    :item-label="item => item"
    :item-value="item => item"
    :error="null"
    :hide-feedback="true"
    placeholder="View per page"
    @input="val => onChangePageLimit(val)"
  />
</template>

<script>
import BasePicker from '@/components/input/base/BasePicker'
import { mapGetters } from 'vuex'

export default {
  name: 'PageSize',
  components: { BasePicker },
  props: {
    items: {
      type: Array,
      required: true,
    },
    defaultPage: {
      type: Number,
      required: true,
    },
    defaultLimit: {
      type: Number,
      required: true,
    },
  },
  computed: {
    selectBoxValue() {
      return this.$route.query.limit ? this.$route.query.limit : this.defaultLimit.toString()
    },
  },
  methods: {
    onChangePageLimit(val) {
      this.$router.replace({ query: { ...this.$route.query, limit: val, page: this.defaultPage.toString() } })
    },
  },
}
</script>

<style scoped></style>
