<template>
  <div class="hero space-y-8 md:space-y-0 space-x-4">
    <div class="hero-image p-0">
      <slot name="image"></slot>
    </div>

    <div class="hero-container">
      <div>
        <h3 class="h3 hero-title">
          <slot name="title"></slot>
        </h3>

        <div class="hero-description">
          <slot name="description"></slot>
        </div>
      </div>

      <div
        v-if="!!$slots['buttons']"
        class="hero-buttons"
      >
        <slot name="buttons" />
      </div>

      <div class="hero-links">
        <slot name="links"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'hero',
}
</script>

<style lang="scss" scoped>
.hero {
  @apply flex flex-col;

  @screen md {
    @apply flex-row;
  }

  &-image {
    @apply max-w-full max-h-full self-center;
    flex: 0 0 auto;

    img {
      @apply mx-auto;
    }
  }

  &-container {
    @apply flex flex-col justify-center;
  }

  &-title {
    @apply m-auto my-5 text-center;
    width: max-content;
  }

  &-description {
    @apply mb-6;
  }

  &-buttons {
    @apply mb-6;
    .wrapper {
      @apply block;
    }
  }

  &-links {
    @apply mb-5;
    a {
      &:after {
        @apply text-bb-neon-purple mx-1;
        content: ' | ';
      }

      &:last-child:after {
        content: '';
      }
    }
  }
}

@screen md {
  .hero {
    @apply flex;

    &-title {
      margin: inherit;
      @apply text-left my-5;
    }

    &-buttons {
      .wrapper {
        @apply flex;
      }
    }
  }
}
</style>
